import React, {ChangeEvent, FC, useEffect, useRef, useState} from 'react';
import useWhyDidYouUpdate from "../../hooks/useWhyDidYouUpdate";
import {IMedia} from "../../interfaces/common";
import {useDispatch} from "react-redux";

import Cropper from "react-cropper";
import CropperType from 'cropperjs';
import "cropperjs/dist/cropper.css";
import "./media-image-editor.scss";

import {Close, Crop, Move, Reload, ReturnUpBack, ReturnUpForward, Contrast} from "react-ionicons"
import {editMedia} from "../../store/app/actions";

interface MediaImageEditorProps {
    media: IMedia,
    onEdit: () => void
}

const MediaImageEditor: FC<MediaImageEditorProps> = (props) => {
    useWhyDidYouUpdate("MediaImageEditor", props);
    const {media, onEdit} = props;
    const dispatch = useDispatch();

    const imageRef = useRef<HTMLImageElement>(null);

    const editor = useRef<HTMLImageElement>(null);
    const editorInstance = useRef<CropperType | null>(null);

    const [showRotate, setShowRotate] = useState(false);
    const [showContrast, setShowContrast] = useState(false);
    const angleRef = useRef(0);

    const [mode, setMode] = useState<Cropper.DragMode>("none");

    useEffect(() => {


        return () => {
            // editorInstance.current?.destroy();
        }
    }, []);

    const __apply = () => {
        const data = editorInstance.current?.getCroppedCanvas();
        dispatch(editMedia(media, data?.toDataURL()));
        onEdit();
    }

    const __onReload = () => {
        editorInstance.current?.reset()
    }
    const __onClear = () => {
        editorInstance.current?.reset();
        editorInstance.current?.clear();
    }
    const __onCrop = () => {
        editorInstance.current?.crop();
    }

    const __rotateToAngle = () => {
        setShowRotate(prev => !prev);
    }

    const __rotate = (angle: number) => () => {
        angleRef.current += angle;
        editorInstance.current?.rotate(angle)
    };

    const __onMode = (mode: Cropper.DragMode) => () => {
        editorInstance.current?.setDragMode(mode);
        setMode(mode);
    }

    const __cancel = () => {
        editorInstance.current?.reset();
        onEdit();
    }

    return (
        <div className="media-image-editor">
            <Cropper
                src={media.url}
                style={{height: 600, width: "100%"}}


                guides={true}
                autoCrop={false}
                // crop={__onCrop}
                ref={editor}
                dragMode={"none"}
                onInitialized={(instance) => {
                    editorInstance.current = instance;
                    editorInstance.current?.reset();
                }}
            />
            <div className="actions">
                <div className="group">
                    <button onClick={__onMode("move")} className={`${mode === "move" ? "active" : ""}`}><Move
                        color={"#fff"}/></button>
                    <button onClick={__onMode("crop")} className={`${mode === "crop" ? "active" : ""}`}><Crop
                        color={"#fff"}/></button>
                </div>
                <div className="group">
                    <button onClick={__rotate(90)}><ReturnUpForward color={"#fff"}/></button>
                    <button onClick={__rotate(-90)}><ReturnUpBack color={"#fff"}/></button>
                    <button onClick={__rotateToAngle} className={`${showRotate ? "active" : ""}`}><Reload color={"#fff"}/></button>
                </div>
                {/*<div className="group">*/}
                {/*    <button onClick={() => setShowContrast(prev => !prev)}*/}
                {/*            className={`${showContrast ? "active" : ""}`}*/}
                {/*    ><Contrast color={"#fff"} /></button>*/}
                {/*</div>*/}
                <div className="group">
                    <button onClick={__onClear}><Close color={"#fff"}/></button>
                </div>
                <div className="group">
                    <button onClick={__apply} className={"btn-success btn-auto"}>Salva immagine</button>
                    <button onClick={__cancel} className={"btn-danger btn-auto"}>Cancella</button>
                </div>
            </div>
            {showRotate && <RangeSlider title={"Rotate angle"} min={-90} max={90} onChange={(value) => {
                editorInstance.current?.rotateTo(value + angleRef.current);
            }}/>}

            {/*{showContrast && <RangeSlider title={"Contrast"} min={-100} max={100} onChange={(value) => {*/}
            {/*    // editorInstance.current?.rotateTo(value + angleRef.current);*/}
            {/*}}/>}*/}
        </div>
    );
};

export default MediaImageEditor;

interface RotateSliderProps {
    title: string
    min: number
    max: number
    onChange: (value: number) => void
}

const RangeSlider: FC<RotateSliderProps> = (props) => {
    const {title, onChange, min, max} = props;
    const [value, setValue] = useState(0);

    const __onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(parseInt(e.target.value));
        onChange(parseInt(e.target.value));
    }

    return <div className="rotate-slider">
        <span>{title}</span>
        <span>{value}</span>
        <input type="range" min={min} max={max} value={value} onChange={__onChange} />
    </div>
}
