import React, {FC, useState} from 'react';
import useWhyDidYouUpdate from "../../hooks/useWhyDidYouUpdate";
import AbstractPopup from "../common/AbstractPopup";
import "./category-delete-modal.scss";
import {ICategory} from "../../interfaces/common";
import {useDispatch} from "react-redux";
import {deleteCategory} from "../../store/app/actions";
import Loader from "../loader/Loader";

interface CategoryDeleteModalProps {
    category: ICategory
    onClose: () => void
}

const CategoryDeleteModal: FC<CategoryDeleteModalProps> = (props) => {
    useWhyDidYouUpdate("CategoryDeleteModal", props);
    const {category, onClose} = props;

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const __onDelete = () => {
        setLoading(true);
        dispatch(deleteCategory(category, () => {
            onClose();
        }));
    }

    return (
        <AbstractPopup onClose={onClose}>
            <div className="category-delete-modal">
                <h1>Sei sicuro di voler eliminare l'intera categoria `{category.title}`?</h1>

                {loading && <Loader />}

                <div className="actions">
                    <button className="btn-success" onClick={__onDelete}>Elimina</button>
                    <button className="btn-danger" onClick={onClose}>Cancella</button>
                </div>
            </div>
        </AbstractPopup>
    );
};

export default CategoryDeleteModal;
