import {DragEvent, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../store";
import {addUploadItems, removeUploadItem, showNotification, uploadFile} from "../store/app/actions";

export interface UploadingFile {
    file: File,
    id: string
    progress: number,

    isUploading?: boolean
}

export default function useUpload() {
    const {
        activeCategory,
        activeTag,
        isAuthorized,
        uploadingQueue
    } = useSelector((store: ApplicationState) => store.app);
    const dispatch = useDispatch();

    useEffect(() => {
        if (uploadingQueue.length != 0) {
            __processQueue(uploadingQueue);
        }
    }, [uploadingQueue]);

    const __processQueue = async (queue: UploadingFile[]) => {
        for (let file of queue) {
            await __processFile(file);
        }
    }

    const __processFile = (file: UploadingFile) => {
        if (file.isUploading) return;

        return new Promise((resolve, reject) => {
            file.isUploading = true;
            dispatch(uploadFile(file, {tag: activeTag, category: activeCategory}, (response: any) => {
                if (response) {
                    if (response.duplicates && response.duplicates.length > 0) {
                        dispatch(showNotification("Il file caricato potrebbe avere dei duplicati", "warning"))
                    } else {
                        dispatch(showNotification("Caricato", "default"))
                    }
                    resolve(true);
                    setTimeout(() => {
                        dispatch(removeUploadItem(file))
                    }, 3000);
                } else {
                    dispatch(showNotification("Errore di caricamento", "error"))
                    resolve(false);
                }
            }, (e: ProgressEvent) => {
                file.progress = Math.round(e.loaded * 100 / e.total);
            }))
        })
    }

    const __uploadFile = (file: UploadingFile) => {

    }

    const __uploadFiles = (files: File[]) => {
        const __files = files.map((file) => {
            return {
                id: Math.random().toString(32),
                file,
                progress: 0,
            }
        });

        dispatch(addUploadItems(__files))


        // files.forEach((file) => {
        //     const __file = {
        //         id: Math.random().toString(32),
        //         file,
        //         progress: 0,
        //     }
        //     setUploadingItems((prevState) => {
        //         return [
        //             ...prevState,
        //             __file
        //         ];
        //     })
        //
        //
        //
        //
        // })
    }

    const handlePaste = (e: any) => {
        if (!isAuthorized) return false;
        e.preventDefault();
        const files = [];
        const items = e.clipboardData?.items;
        for (let index in items) {
            const item = items[index];
            if (item.kind === 'file') {
                files.push(item.getAsFile());
            }
        }

        __uploadFiles(files);
    }

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!isAuthorized) return false;
        e.preventDefault();
        const files: File[] = [];
        if (e.target.files) {
            for (let i = 0; i < e.target.files.length; i++) {
                files.push(e.target.files[i]);
            }
        }

        __uploadFiles(files);
    }

    const handleDrop = (e: DragEvent<HTMLDivElement>) => {
        if (!isAuthorized) return false;
        e.preventDefault();
        const files = [];

        for (let i = 0; i < e.dataTransfer.files.length; i++) {
            const file = e.dataTransfer.files[i];
            files.push(file);
        }

        __uploadFiles(files);
    }

    return {
        handlePaste,
        handleInput,
        handleDrop,
    }

}
