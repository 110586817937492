import React, {FC, useEffect} from 'react';
import useWhyDidYouUpdate from "../../hooks/useWhyDidYouUpdate";
import "./notification.scss"
import {useDispatch} from "react-redux";
import {hideNotification} from "../../store/app/actions";


interface NotificationProps {
    notification: any
}

const Notification: FC<NotificationProps> = (props) => {
    useWhyDidYouUpdate("Notification", props);
    const {notification} = props;

    const dispatch = useDispatch();


    useEffect(() => {
        setTimeout(() => {
            dispatch(hideNotification());
        }, 5000);
    }, [dispatch])

    return (
        <div className={`notification ${notification.type}`}>
            {notification.text}
        </div>
    );
};

export default Notification;
