import {AuthResponse} from "../api/api";
import {Dispatch} from "redux";
import {login} from "../store/app/actions";


export default class AuthService {

    static async logout() {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
    }

    static async login(loginData: AuthResponse) {
        localStorage.setItem("token", loginData.token);
        localStorage.setItem("refresh_token", loginData.refresh_token);
    }

    static async isAuthorized(dispatch: Dispatch<any>) {
        // try {
        if (localStorage.getItem("token")) {
            dispatch(login({token: localStorage.getItem("token")}))
            // const response = await axios.post("/api/token/refresh", {refresh_token: localStorage.getItem("refresh_token")})
            // debugger;
        }
        // } catch (e) {
        //     console.log(e)
        //     debugger
        // }
    }
}
