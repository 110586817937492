import React, {FC} from 'react';
import useWhyDidYouUpdate from "../../hooks/useWhyDidYouUpdate";
import {ICategory} from "../../interfaces/common";
import CategoryItem from "./CategoryItem";
import CategoryCreate from "./CategoryCreate";

interface CategoryItemListProps {
    categories: ICategory[]
    isExpanded?: boolean
    isRoot?: boolean
}

const CategoryItemList: FC<CategoryItemListProps> = (props) => {
    useWhyDidYouUpdate("CategoryItemList", props);
    const {categories, isExpanded = false, isRoot = false} = props;

    const __categories = isRoot ? categories.filter(__ => !!__).filter(__cat => !__cat.parentId) : categories;

    return (
        <div className={`category-item-list ${isExpanded ? "expanded" : "collapsed"}`}>
            <div style={{margin: "0.25rem 0"}}>
                <CategoryCreate parentCategory={null} show/>
            </div>

            {__categories.map(__cat => {
                    if (__cat) {
                        return <CategoryItem
                            key={`category-${__cat.id}`}
                            category={__cat}/>
                    }
                }
            )}
        </div>
    );
};

export default CategoryItemList;
