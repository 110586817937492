import axios from "axios";

export const API_URL = "/api";

const $api = axios.create({
    withCredentials: true,
    baseURL: API_URL,
});

$api.interceptors.request.use((config) => {
    if (localStorage.getItem("token")) {
        config.headers!.Authorization = `Bearer ${localStorage.getItem("token")}`;
    }
    if (localStorage.getItem("server")) {
        config.headers!.ServerID = `${localStorage.getItem("server")}`;
    }
    return config
});

$api.interceptors.response.use((config) => {
    return config;
}, async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && error.config && !error.config._isRetry) {
        originalRequest._isRetry = true;
        try {
            const response = await axios.post("/api/token/refresh", {refresh_token: localStorage.getItem("refresh_token")})
            debugger;
        } catch (e) {
            console.log(e)
            debugger
        }
    }
})

export default $api;
