import {Reducer} from "redux";
import {AppActions, appState} from "./types";
import {ICategory, IMedia} from "../../interfaces/common";


export const initialState: appState = {
    menuOpen: false,

    isAuthorized: false,
    notification: null,

    headerText: "",

    tags: [],
    activeTag: null,

    categories: [],
    activeCategory: null,

    mediaLoading: true,

    media: [],
    selectedMedia: [],

    uploadingQueue: [],
};


const reducer: Reducer<appState> = (state = initialState, action) => {

    switch (action.type) {
        case AppActions.TOGGLE_MENU:
            return {
                ...state,
                menuOpen: !state.menuOpen,
            }
        case AppActions.SHOW_NOTIFICATION:
            return {
                ...state,
                notification: action.payload,
            }
        case AppActions.UPDATE_HEADER:
            return {
                ...state,
                media: [],
                mediaLoading: true,
                headerText: action.payload,
                activeCategory: null,
                activeTag: null,
            }
        case AppActions.HIDE_NOTIFICATION:
            return {
                ...state,
                notification: null,
            }

        case AppActions.LOGIN:
            return {
                ...state,
                isAuthorized: true,
            }
        case AppActions.LOGOUT:
            return {
                ...state,
                isAuthorized: false,
            }
        case AppActions.MOVE_MEDIA_TO_CATEGORY:
            return {
                ...state,
                media: state.media.filter(_ => _.id !== action.payload.media.id)
            }
        case AppActions.ADD_TAG:
            return {
                ...state,
                tags: state.tags.filter(_ => _.id !== action.payload.id).concat(action.payload),
            }
        case AppActions.LOAD_TAGS:
            return {
                ...state,
                tags: action.payload,
            }
        case AppActions.LOAD_CATEGORIES:
            return {
                ...state,
                categories: action.payload,
            }
        case AppActions.SET_ACTIVE_TAG:
            return {
                ...state,
                activeTag: action.payload,
                activeCategory: null,
                mediaLoading: true,
                headerText: action.payload.title,
                media: [],
            }
        case AppActions.SET_ACTIVE_CATEGORY: {
            if (!action.payload) {
                return {
                    ...state,
                    activeCategory: null,
                    media: [],
                    headerText: process.env.REACT_APP_TITLE!,
                }
            }
            return {
                ...state,
                activeCategory: action.payload,
                activeTag: null,
                mediaLoading: true,
                headerText: action.payload.title,
                // media: [],
            }
        }
        case AppActions.LOAD_MEDIA:
            return {
                ...state,
                mediaLoading: false,
                media: action.payload,
            }
        case AppActions.UPDATE_CATEGORY:
            let __categoryIdx = state.categories.findIndex(_ => _.id === action.payload.id);
            state.categories[__categoryIdx] = action.payload;
            return {
                ...state,
                categories: [...state.categories],
            }
        case AppActions.DELETE_CATEGORY:
            return {
                ...state,
                categories: [...state.categories.filter(_ => _.id !== action.payload.id)],
            }
        case AppActions.UPDATE_MEDIA:
            let __mediaIdx = state.media.findIndex(_ => _.id === action.payload.id);
            const __media = {
                ...action.payload,
                url: action.payload.url + `?${Date.now()}`,
                thumbnailUrl: action.payload.url + `?${Date.now()}`,
                isUpdated: true,
            }
            state.media[__mediaIdx] = __media;
            return {
                ...state,
                mediaLoading: false,
                media: [...state.media],
            }
        case AppActions.UPDATE_MEDIAS:
            const medias = action.payload;
            medias.forEach((media: IMedia) => {
                let __mediaIdx = state.media.findIndex(_ => _.id === media.id);
                state.media[__mediaIdx] = media;
            })
            return {
                ...state,
                mediaLoading: false,
                media: [...state.media],
            }
        case AppActions.DELETE_MEDIA: {
            const categories = updateCategoryCount(state.categories, action.payload.categoryId, -1);
            return {
                ...state,
                categories: [...categories],
                mediaLoading: false,
                media: state.media.filter(_ => _.id !== action.payload.id),
            }
        }
        case AppActions.DELETE_SELECTED_MEDIA: {
            const ids = state.selectedMedia.map(_ => _.id);
            const categories = state.categories;
            state.selectedMedia.forEach((__media) => {
                updateCategoryCount(categories, __media.categoryId, -1);
            });
            return {
                ...state,
                categories: [...categories],
                media: state.media.filter(_ => ids.indexOf(_.id) < 0),
                selectedMedia: [],
            }
        }
        case AppActions.UPLOAD_MEDIA: {
            const categories = updateCategoryCount(state.categories, action.payload.categoryId, 1);
            return {
                ...state,
                categories: [...categories],
                mediaLoading: false,
                media: state.media.concat(action.payload),
            }
        }
        case AppActions.SELECT_MEDIA:

            let selectedMedia = state.selectedMedia;
            const mediaIdx = selectedMedia.find(_ => _.id === action.payload.id)
            if (mediaIdx) {
                selectedMedia = selectedMedia.filter(_ => _.id !== action.payload.id)
            } else {
                selectedMedia = [...selectedMedia, action.payload]
            }

            return {
                ...state,
                selectedMedia: [...selectedMedia]
            }
        case AppActions.SELECT_MEDIA_RANGE:
            const __range = []
            for (let i = action.payload.start; i <= action.payload.end; i++) {
                __range.push(state.media[i])
            }
            return {
                ...state,
                selectedMedia: [...__range]
            }
        case AppActions.CLEAR_SELECTION:
            return {
                ...state,
                selectedMedia: [],
            }

        case AppActions.ADD_UPLOADING_ITEMS: {
            return {
                ...state,
                uploadingQueue: [...state.uploadingQueue, ...action.payload]
            }
        }
        case AppActions.REMOVE_UPLOADING_ITEM: {
            return {
                ...state,
                uploadingQueue: state.uploadingQueue.filter(__item => __item.id !== action.payload.id),
            }
        }
        case AppActions.REMOVE_UPLOADING_ITEMS: {
            return {
                ...state,
                uploadingQueue: state.uploadingQueue.filter(__item => action.payload.indexOf(__item) >= 0),
            }
        }
        default:
            return state;
    }
}

const updateCategoryCount = (list: ICategory[], id: number, count: number) => {
    list.forEach((__cat) => {
        if (!__cat) return;

        if (__cat.id === id) {
            if (!__cat.count) {
                __cat.count = 0;
            }

            __cat.count += count;
        }
        if (__cat.children.length > 0) {
            updateCategoryCount(__cat.children, id, count);
        }
    });

    return list;
}


export {reducer as appReducer};
