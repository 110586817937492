import React, {FC} from 'react';
import useWhyDidYouUpdate from "../hooks/useWhyDidYouUpdate";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../store";
import {ITag} from "../interfaces/common";
import {setActiveTag} from "../store/app/actions";

interface TagListProps {
    filter: string
}

const TagList: FC<TagListProps> = (props) => {
    useWhyDidYouUpdate("TagList", props);
    const {filter} = props;

    const {tags, activeTag,} = useSelector((store: ApplicationState) => store.app);

    const dispatch = useDispatch();

    const __onTagClick = (tag: ITag) => {
        dispatch(setActiveTag(tag))
    }

    return (
        <div className="tag-list">

            <TagItem isActive={activeTag?.id === 0} tag={{id: 0, title: "Uncategorized"}} onClick={() => {
                __onTagClick({id: 0, title: "Uncategorized"})
            }}/>
            {tags
                .filter(_ => _.cnt! > 0)
                .filter(_ => _.title.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
                .sort((a, b) => a.id > b.id ? 1 : -1).map(__tag =>
                    <TagItem isActive={activeTag?.id === __tag.id} onClick={__onTagClick} tag={__tag}
                             key={`tag-${__tag.id}`}/>
                )}

        </div>
    );
};

interface TagItemProps {
    tag: ITag
    isActive: boolean
    onClick: (tag: ITag) => void
}

const TagItem: FC<TagItemProps> = (props) => {
    const {tag, onClick, isActive} = props;

    return (
        <a href="#" className={(isActive ? "active" : "")}
           onClick={() => onClick(tag)}>{tag.title} {tag.cnt && `(${tag.cnt})`}</a>
    )

}


export default TagList;
