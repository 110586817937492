import {applyMiddleware, compose, createStore, Store} from 'redux';
import thunk from 'redux-thunk';
// import { routerMiddleware } from 'connected-react-router';
// import { History } from 'history';
import {ApplicationState, createRootReducer} from './store';

export default function configureStore(initialState: ApplicationState): Store<ApplicationState> {
    const store = createStore(
        createRootReducer(),
        initialState,
        compose(
            applyMiddleware(thunk),

            // @ts-ignore
            window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
        ),
    );

    return store;
}
