import React, {useEffect, useState} from 'react';
import './App.scss';
import Home from "./screens/Home";
import {Provider} from 'react-redux'
import configureStore from "./configureStore";
import SplashScreen from "./components/SplashScreen";

const initialState: any = {};

const store = configureStore(initialState);

function App() {
    const [server, setServer] = useState<number | undefined>();

    useEffect(() => {
        if (localStorage.getItem("server")) {
            setServer(parseInt(localStorage.getItem("server")!));
        }
    }, []);

    const __onSwitch = () => {
        localStorage.removeItem("server");
        setServer(undefined);
    }

    return <Provider store={store}>
        {server ? <Home onSwitch={__onSwitch}/> : <SplashScreen onSelect={(id) => setServer(id)}/>}
    </Provider>
}

export default App;
