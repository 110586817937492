import {IMedia, ITag} from "../../interfaces/common";
import React, {ChangeEvent, FC, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import {WithContext as ReactTags} from 'react-tag-input';
import {createTag, showNotification, updateMedia} from "../../store/app/actions";
import MediaDeleteModal from "./MediaDeleteModal";

interface MediaInfoFormProps {
    media: IMedia,
    onClose: () => void
    onCancel: () => void
    onChange: (info: IMedia) => void,
}


const KeyCodes = {
    comma: 188,
    enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export const MediaInfoForm: FC<MediaInfoFormProps> = (props) => {
    const {onChange, media, onCancel} = props;
    const {tags, categories} = useSelector((store: ApplicationState) => store.app);
    const [showMediaDeleteModal, setShowMediaDeleteModal] = useState<IMedia | IMedia[] | undefined>();

    const dispatch = useDispatch();

    const __setData = (param: keyof IMedia) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        onChange({
            ...media,
            [param]: e.target.value,
        })
    }

    const suggestions = tags.map(__tag => {
        return {
            id: __tag.id.toString(),
            text: __tag.title
        };
    });

    const handleDelete = (i: any) => {
        onChange({
            ...media,
            tags: media.tags.filter((tag, index) => index !== i)
        })
    };

    const handleInputBlur = (text: string) => {
        if (text !== "") {
            handleAddition({id: text, text})
        }
    }

    const handleAddition = (tag: any) => {
        let __tag = tags.find(_ => _.id.toString() === tag.id);
        if (__tag) {
            onChange({
                ...media,
                tags: [...media.tags, __tag],
            });
        } else {
            dispatch(createTag({title: tag.text}, (tag: ITag) => {
                onChange({
                    ...media,
                    tags: [...media.tags, tag],
                });
            }))
        }
    };

    const __onSave = () => {
        dispatch(updateMedia(media, (media: IMedia) => {
            dispatch(showNotification("Salvato", "default"));
        }));
    }


    function __onDelete() {
        setShowMediaDeleteModal(media);
    }

    const __stopPropagation = (e:any) => {
        e.stopPropagation();
    }

    return (
        <>
            <div className="media-info" onKeyDown={__stopPropagation}>
                <div className="form-row">
                    <input type="text" value={media.title ?? ""} onChange={__setData("title")} className="form-control"
                           placeholder="Titolo"/>
                </div>
                <div className="form-row">
                    <textarea rows={4} value={media.description ?? ""} onChange={__setData("description")}
                              placeholder="Descrizione"
                              className="form-control"/>
                </div>
                <div className="form-row">
                    <select name="" id="" value={media.categoryId} className="form-control"
                            onChange={__setData("categoryId")}>
                        {categories.map(__category => {
                            return <option key={`category-${__category.id}`}
                                           value={__category.id}>{__category.title}</option>
                        })}
                    </select>
                </div>
                <div className="form-row">
                    <ReactTags
                        tags={media.tags?.map(__tag => ({id: __tag.id.toString(), text: __tag.title}))}
                        suggestions={suggestions}
                        delimiters={delimiters}
                        handleDelete={handleDelete}
                        handleAddition={handleAddition}
                        handleInputBlur={handleInputBlur}
                        allowDragDrop={false}
                        placeholder={"Premi Invio per aggiungere un nuovo tag"}
                        classNames={{
                            tagInputField: "form-control",
                        }}
                        inputFieldPosition="bottom"
                        autocomplete


                    />
                </div>

                <div className="actions">
                    <button className="btn btn-success btn-mini btn-block" onClick={__onSave}>Salva</button>
                    <button className="btn btn-danger btn-mini btn-block" onClick={__onDelete}>Rimuova</button>
                    <button className="btn btn-edit btn-mini btn-block" onClick={onCancel}>Cancella</button>
                </div>


            </div>
            {showMediaDeleteModal &&
                <MediaDeleteModal media={showMediaDeleteModal} onClose={() => setShowMediaDeleteModal(undefined)}/>}
        </>
    )
}
