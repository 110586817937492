import $api from "./index";
import {AxiosResponse} from "axios";
import {ICategory, IMedia, ITag} from "../interfaces/common";

export interface AuthResponse {
    token: string,
    refresh_token: string,
}

interface IInitialResponse {
    medias: IMedia[]
    tags: ITag[]
    categories: ICategory[]
}

export default class Api {

    static async login(username: string, password: string): Promise<AxiosResponse<AuthResponse>> {
        return $api.post("/login_check", {username, password,})
    }

    static async refreshToken(data: any): Promise<AxiosResponse<AuthResponse>> {
        return $api.post("/token/refresh", data)
    }

    static async logout(): Promise<void> {
        return $api.post("/logout")
    }

    static search(query: string): Promise<AxiosResponse<IMedia[]>> {
        return $api.get<IMedia[]>(`/search?query=${query}`)
    }

    static getMedia(id: number): Promise<AxiosResponse<IMedia>> {
        return $api.get<IMedia>(`/media/${id}`)
    }

    static deleteMedia(id: number): Promise<AxiosResponse> {
        return $api.delete(`/media/${id}`)
    }

    static deleteMedias(medias: number[]): Promise<AxiosResponse> {
        return $api.patch(`/media/`, medias)
    }


    static getIndexData(categoryId?: number): Promise<AxiosResponse<IInitialResponse>> {
        return $api.get<IInitialResponse>(`/index${categoryId ? `/${categoryId}` : ""}`)
    }

    static getCategoryMedia(categoryId: string | number): Promise<AxiosResponse<IMedia[]>> {
        return $api.get(`/category/${categoryId}/media`)
    }

    static uploadFile(data: any, onProgress: (progressEvent: any) => void): Promise<AxiosResponse<IMedia>> {
        return $api.post(`/media/upload`, data, {
            onUploadProgress: onProgress
        })
    }

    static createTag(data: any): Promise<AxiosResponse<ITag>> {
        return $api.post<ITag>("/tag/", data)
    }

    static getTags(): Promise<AxiosResponse<ITag[]>> {
        return $api.get<ITag[]>("/tag/")
    }

    static getTagMedias(tag: ITag): Promise<AxiosResponse<IMedia[]>> {
        return $api.get<IMedia[]>(`/tag/${tag.id}/media`)
    }



    //TODO Rework
    static uploadFileToCategory(data: any): Promise<AxiosResponse<IMedia>> {
        return $api.post(`/media/upload`, data)
    }

    static createCategory(data: any): Promise<AxiosResponse<ICategory>> {
        return $api.post("/category/", data)
    }

    static updateCategory(id: number, data: any): Promise<AxiosResponse<ICategory>> {
        return $api.put(`/category/${id}`, data)
    }

    static deleteCategory(category: ICategory): Promise<AxiosResponse<ICategory>> {
        return $api.delete(`/category/${category.id}`)
    }

    static updateMedia(id: number, data: IMedia): Promise<AxiosResponse<IMedia>> {
        return $api.put(`/media/${id}`, data)
    }

    static editMedia(id: number, data: string): Promise<AxiosResponse<IMedia>> {
        return $api.put(`/media/${id}/edit`, data)
    }

    static findDuplicates(ids: number[]): Promise<AxiosResponse<IMedia[]>> {
        return $api.post(`/media/duplicates`, ids);
    }
}
