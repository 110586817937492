import React, {FC} from 'react';
import useWhyDidYouUpdate from "../../hooks/useWhyDidYouUpdate";
import "./loader.scss";

interface LoaderProps {

}

const Loader: FC<LoaderProps> = (props) => {
    useWhyDidYouUpdate("Loader", props);

    return <div className="loader"/>;
};

export default Loader;
