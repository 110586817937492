import React, {FC} from 'react';
import useWhyDidYouUpdate from "../../hooks/useWhyDidYouUpdate";
import {ICategory, IMedia} from "../../interfaces/common";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../store";

interface MediaInfoProps {
    media: IMedia
}

const MediaInfo: FC<MediaInfoProps> = (props) => {
    useWhyDidYouUpdate("MediaInfo", props);
    const {media} = props;
    const {categories} = useSelector((store: ApplicationState) => store.app);


    return (
        <div className="media-info">
            <h1>{media.title}</h1>
            <CategoryPath categories={categories} categoryId={media.categoryId}/>
            {(media.tags && media.tags.length > 0) && <ul>{media.tags.map(_ => <li key={_.id}>{_.title}</li>)}</ul>}
        </div>
    );
};

interface CategoryPathProps {
    categories: ICategory[]
    categoryId?: number
}

const CategoryPath: FC<CategoryPathProps> = ({categories, categoryId}) => {
    let category = categories.find(_ => _.id === parseInt(String(categoryId)));
    let path = findParent(categories, category)

    return <div>{path.map<React.ReactNode>(__category => {
        return <a href={`/category/${__category.id}`} target="_blank" key={`category-${__category.id}`}
                  className="media-path">{__category.title}</a>
    }).reduce((prev, curr) => [prev, ' / ', curr], [])}</div>
}

const findParent = (categories: ICategory[], category?: ICategory): ICategory[] => {

    const path: ICategory[] = [];
    if (category) {
        path.push(category);

        let __category: ICategory = category;
        while (__category.parentId != null) {
            const parent = categories.find(_ => _.id === parseInt(String(__category.parentId)));
            if (parent) {
                path.push(parent);
                __category = parent;
            }
        }
        return path.reverse();
    } else {
        return [];
    }
}

export default MediaInfo;
