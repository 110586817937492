import React, {FC, useState} from 'react';
import useWhyDidYouUpdate from "../../hooks/useWhyDidYouUpdate";
import Loader from "../loader/Loader";
import {IMedia} from "../../interfaces/common";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import {Search} from "react-ionicons"

import ReactPanZoom from './PanViewer';

interface MediaImageProps {
    media: IMedia
    onChange: () => void
}

const MediaImage: FC<MediaImageProps> = (props) => {
    useWhyDidYouUpdate("MediaImage", props);
    const {media, onChange} = props;
    const {isAuthorized} = useSelector((store: ApplicationState) => store.app);

    const [loading, setLoading] = useState(false);
    const [isZoom, setIsZoom] = useState(false);

    return (
        <>
            <div className="media-wrapper">
                {isZoom ?
                    <ReactPanZoom image={media.url} buttons={["zoom", "rotate", "reset"]}
                                  onDisable={() => setIsZoom(false)}/>
                    : <img src={media.url} className="media" alt={media.title}/>}
                {loading && <Loader/>}
                {!isZoom &&
                    <button className="media-zoom" onClick={() => setIsZoom(true)}><Search color={"#4C68C1"}/></button>}
            </div>
            {media.description &&
                <div className="media-viewer-description">{media.description}</div>}

            <div className="media-actions">
                {(isAuthorized) && <button
                    className="btn btn-mini btn-edit"
                    onClick={onChange}>Modifica media
                </button>}
                <a href={media.url} className="btn btn-mini btn-success" download={`${media.title}.jpg`}>Scarica</a>
            </div>

        </>
    );
};

export default MediaImage;
