import React, {ChangeEvent, FC, useEffect, useRef, useState} from 'react';
import useWhyDidYouUpdate from "../../hooks/useWhyDidYouUpdate";
import {ICategory} from "../../interfaces/common";
import {useDispatch, useSelector} from "react-redux";
import {loadCategories} from "../../store/app/actions";
import Api from "../../api/api";
import {ApplicationState} from "../../store";

interface CategoryCreateProps {
    parentCategory: ICategory | null,
    onCreate?: (category: ICategory) => void
    show?: boolean
    label?: string
}

const CategoryCreate: FC<CategoryCreateProps> = (props) => {
    useWhyDidYouUpdate(`CategoryCreate-${props.parentCategory?.id}`, props);
    const {parentCategory, onCreate, show, label = "Nuova cartella"} = props;
    const inputRef = useRef<HTMLInputElement>(null);

    const {isAuthorized} = useSelector((store: ApplicationState) => store.app);

    const dispatch = useDispatch();

    const [showInput, setShowInput] = useState(false);
    const [title, setTitle] = useState("");

    const __onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && title !== "") {
            Api.createCategory({
                title,
                parent: parentCategory?.id,
            }).then(data => {
                dispatch(loadCategories());
                onCreate && onCreate(data.data);
                setTitle("");
                setShowInput(false);
            })
        } else if (e.key === "Escape") {
            setTitle("");
            setShowInput(false);
        }
    }

    useEffect(() => {
        if (showInput) {
            inputRef.current?.focus();
        }
    }, [showInput])

    const __onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
    }

    if (!isAuthorized) return null;

    return (
        <div className="category-create" style={show ? {display: "block"} : undefined}>
            <input ref={inputRef} placeholder="New folder" type="text"
                   className={`${showInput ? "expanded" : "collapsed"}`} value={title} onChange={__onChange}
                   onKeyDown={__onKeyDown}/>
            <button
                className={`${showInput ? "collapsed" : "expanded"}`}
                onClick={() => {
                    setShowInput(true);
                }}>{label}</button>
        </div>
    );
};

export default CategoryCreate;
