import React, {FC, useState} from 'react';
import useWhyDidYouUpdate from "../../hooks/useWhyDidYouUpdate";
import AbstractPopup from "../common/AbstractPopup";
import Loader from "../loader/Loader";
import "./media-delete-modal.scss";
import {IMedia} from "../../interfaces/common";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import {deleteMedia, deleteMultipleMedia} from "../../store/app/actions";

interface MediaDeleteModalProps {
    onClose: () => void
    media?: IMedia | IMedia[]
}

const MediaDeleteModal: FC<MediaDeleteModalProps> = (props) => {
    useWhyDidYouUpdate("MediaDeleteModal", props);
    const {onClose, media} = props;
    const {selectedMedia,} = useSelector((store: ApplicationState) => store.app);
    const dispatch = useDispatch();


    const [loading, setLoading] = useState(false);


    const __onDelete = () => {
        setLoading(true);
        if (Array.isArray(media)) {
            dispatch(deleteMultipleMedia(media, () => {
                onClose();
            }))
        } else {
            dispatch(deleteMedia(media, () => {
                onClose();
            }))
        }
    }

    return (
        <AbstractPopup onClose={onClose}>
            <div className="media-delete-modal">
                <h1>Sei sicuro di voler eliminare tutte le foto selezionate</h1>
                <ul>
                    {(media && !Array.isArray(media)) && <li>`{media?.title}`</li>}
                    {selectedMedia.length >= 0 && selectedMedia.map(__media => {
                        return <li>`{__media.title}`</li>
                    })}
                </ul>
                {loading && <Loader/>}

                <div className="actions">
                    <button className="btn-success" onClick={__onDelete}>Elimina</button>
                    <button className="btn-danger" onClick={onClose}>Cancella</button>
                </div>
            </div>
        </AbstractPopup>
    );
};

export default MediaDeleteModal;
