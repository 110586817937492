import React, {FC, useState, KeyboardEvent} from 'react';
import useWhyDidYouUpdate from "../hooks/useWhyDidYouUpdate";
import {useDispatch} from "react-redux";
import {search} from "../store/app/actions";
import {Search as SearchIcon} from 'react-ionicons'

interface SearchProps {

}

const Search: FC<SearchProps> = (props) => {
    useWhyDidYouUpdate("Search", props);
    // const {} = props;

    const dispatch = useDispatch();
    const [query, setQuery] = useState("");

    const __onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        e.stopPropagation();

        if (e.key === "Enter" && query.length >= 3) {
            dispatch(search(query))
        }
    }

    return (
        <div className="search">
            <input type="text"
                   placeholder="Cerca"
                   value={query}
                   onKeyDown={__onKeyDown}
                   onChange={e => {
                       e.stopPropagation();
                       setQuery(e.target.value)
                   }}
                   className=""
            />
            <SearchIcon color={"#ccc"} style={{
                position: "absolute",
                top: "14px",
                left: "7px",
            }}/>
        </div>
    );
};

export default Search;
