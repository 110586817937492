import {combineReducers} from 'redux';
import {appReducer} from "./app/reducer";
// import {connectRouter, RouterState} from 'connected-react-router';
// import {History} from 'history';
import {appState} from "./app/types";

export interface ApplicationState {
    app: appState,

}

export const createRootReducer = () => {
    return combineReducers({
        app: appReducer,
    });
}
