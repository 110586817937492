import React, {FC,} from 'react';
import ReactDOM from "react-dom";
import useWhyDidYouUpdate from "../../hooks/useWhyDidYouUpdate";
import {Close} from "react-ionicons"

export interface AbstractPopupProps {
    onClose: () => void
}

const AbstractPopup: FC<AbstractPopupProps> = (props) => {
    useWhyDidYouUpdate("AbstractPopup", props);
    const {children, onClose} = props;

    return ReactDOM.createPortal(
        <>
            <div className="modal-overlay" onClick={onClose}/>
            <button className="modal-close" onClick={onClose}><Close color={"#fff"} width={"60"} height={"60"}/></button>
            {children}
        </>,
        document.getElementById("modal")!
    );
};

export default AbstractPopup;
