import React, {FC, useEffect} from 'react';
import useWhyDidYouUpdate from "../../hooks/useWhyDidYouUpdate";
import "./upload-queue.scss"
import {useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import {UploadingFile} from "../../hooks/useUpload";

interface UploadQueueProps {

}

const UploadQueue: FC<UploadQueueProps> = (props) => {
    useWhyDidYouUpdate("UploadQueue", props);
    const {} = props;

    const {uploadingQueue} = useSelector((store: ApplicationState) => store.app);

    return (
        <div className="upload-queue">
            <h2>Coda di caricamento</h2>
            <div className="list">
                {uploadingQueue.map((__file) => <UploadingFileComponent file={__file} key={__file.id}/>)}
            </div>
        </div>
    );
};

interface UploadingFileProps {
    file: UploadingFile
}

const UploadingFileComponent: FC<UploadingFileProps> = (props) => {
    const {file} = props;

    return <div className="uploading-file">
        <img src={URL.createObjectURL(file.file)} alt=""/>
        <div className="progress" style={{width: `${file.progress}%`}}>{file.progress}%</div>
    </div>
}

export default UploadQueue;
