import React, {FC} from 'react';
import useWhyDidYouUpdate from "../../hooks/useWhyDidYouUpdate";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import CategoryItemList from "./CategoryItemList";

interface CategoryListProps {
    filter: string
}

const CategoryList: FC<CategoryListProps> = (props) => {
    useWhyDidYouUpdate("CategoryList", props);
    const {filter} = props;

    const {categories,} = useSelector((store: ApplicationState) => store.app);

    categories.sort((a, b) => a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1)

    return (
        <div className="category-list">
            <CategoryItemList isRoot={true} categories={categories} isExpanded={true}/>
            {/*{filter*/}
            {/*    ? filter*/}
            {/*    : <CategoryItemList isRoot={true} categories={categories} isExpanded={true}/>}*/}
        </div>
    );
};

export default CategoryList;
