import React, {FC, useEffect} from 'react';
import useWhyDidYouUpdate from "../hooks/useWhyDidYouUpdate";
import ForniDiSopra from "./Screen/forni-di-sopra.png"
import ForniDiSotto from "./Screen/forni-di-sotto.png"
import "./splash-screen.scss";

interface SplashScreenProps {
    onSelect: (serverId: number) => void
}

const SplashScreen: FC<SplashScreenProps> = (props) => {
    useWhyDidYouUpdate("SplashScreen", props);
    const {onSelect} = props;

    useEffect(() => {
        if (localStorage.getItem("server")) {

        } else {

        }
    }, []);

    const onServerSelect = (serverId: number) => () => {
        localStorage.setItem("server", serverId.toString());
        onSelect(serverId);
    }

    return (
        <div className="splash-screen">
            <div className="select">
                <div className="item" onClick={onServerSelect(1)}>
                    <img src={ForniDiSopra} alt="" className="logo"/>
                    <div className="title">Forni di Sopra</div>
                </div>
                <div className="item" onClick={onServerSelect(2)}>
                    <img src={ForniDiSotto} alt="" className="logo"/>
                    <div className="title">Forni di Sotto</div>
                </div>
            </div>
        </div>
    );
};

export default SplashScreen;
