import React, {FC, useState} from 'react';
import useWhyDidYouUpdate from "../hooks/useWhyDidYouUpdate";
import TagList from "./TagList";
import CategoryList from "./category/CategoryList";
import {useSelector} from "react-redux";
import {ApplicationState} from "../store";
import {ChevronBackCircleOutline} from "react-ionicons";

interface SidebarProps {

}

enum SidebarView {
    CATEGORIES = "CATEGORIES",
    TAGS = "TAGS",
}

const Sidebar: FC<SidebarProps> = (props) => {
    useWhyDidYouUpdate("Sidebar", props);
    const {} = props;

    const {menuOpen} = useSelector((store: ApplicationState) => store.app);

    const [activeView, setActiveView] = useState(SidebarView.CATEGORIES);
    const [filter, setFilter] = useState("");

    return (
        <div className={`sidebar ${menuOpen ? "open" : ""}`}>
            <div className="logo">
                <a className="back" href={`//${process.env.REACT_APP_MAIN_DOMAIN}`}>
                    <ChevronBackCircleOutline width={"42.5px"} height={"42.5px"}/>
                </a>
                <div>
                    <a href={`//${process.env.REACT_APP_MAIN_DOMAIN}`} className="title" title="L'arbul dal Comun di Raviei">{process.env.REACT_APP_TITLE}</a>
                    <div className="copyright">
                        {process.env.REACT_APP_EMAIL}
                    </div>
                </div>
            </div>
            {/*<div className="filter">*/}
            {/*    <input type="text" placeholder="Filtro" value={filter} onChange={e => setFilter(e.target.value)}/>*/}
            {/*</div>*/}

            <div className="sidebar-switcher">
                <div className={`sidebar-switcher-item ${activeView === SidebarView.CATEGORIES && "active"}`}
                     onClick={() => setActiveView(SidebarView.CATEGORIES)}>Cartelle
                </div>
                <div className={`sidebar-switcher-item ${activeView === SidebarView.TAGS && "active"}`}
                     onClick={() => setActiveView(SidebarView.TAGS)}>Tags
                </div>
            </div>

            {activeView === SidebarView.TAGS
                ? <TagList filter={filter}/>
                : <CategoryList filter={filter}/>
            }

        </div>
    );
};

export default Sidebar;
