import React, {FC, useEffect, useRef, useState} from 'react';
import useWhyDidYouUpdate from "../../hooks/useWhyDidYouUpdate";
import AbstractPopup, {AbstractPopupProps} from "../common/AbstractPopup";
import AuthService from "../../services/AuthService";
import "./login-popup.scss";
import {useDispatch} from "react-redux";
import Api from "../../api/api";
import Loader from "../loader/Loader";
import {login} from "../../store/app/actions";

interface LoginPopupProps extends AbstractPopupProps {

}

const LoginPopup: FC<LoginPopupProps> = (props) => {
    useWhyDidYouUpdate("LoginPopup", props);
    const {onClose} = props;
    const dispatch = useDispatch();

    const inputRef = useRef<HTMLInputElement>(null);
    const [loginData, setLoginData] = useState({username: "", password: ""});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const __onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setLoading(true);
        Api.login(loginData.username, loginData.password)
            .then(data => {
                AuthService.login(data.data);
                dispatch(login(data.data))
                onClose();
            })
            .catch((err) => {
                setError("Incorrect login or password");
            })
            .finally(() => {
                setLoading(false);
            })
    }

    useEffect(() => {
        setTimeout(() => {
            inputRef.current?.focus();
        }, 50);
    }, []);

    return (
        <AbstractPopup onClose={onClose}>
            <form className="login-popup">
                {loading && <div className="loading"><Loader/></div>}
                <div className="form-row">
                    <label htmlFor="">Username</label>
                    <input className="form-control" type="text" ref={inputRef} name="login" value={loginData.username}
                           onChange={(e) => setLoginData({...loginData, username: e.target.value})}/>
                </div>
                <div className="form-row">
                    <label htmlFor="">Password</label>
                    <input className="form-control" type="password" value={loginData.password} name="password"
                           onChange={(e) => setLoginData({...loginData, password: e.target.value})}/>
                </div>
                {error && <div className="error">{error}</div>}
                <button onClick={__onSubmit} className="login-btn">Login</button>
            </form>
        </AbstractPopup>
    );
};

export default LoginPopup;
