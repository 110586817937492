import React, {FC, useEffect, useState} from 'react';
import useWhyDidYouUpdate from "../../hooks/useWhyDidYouUpdate";
import {IMedia} from "../../interfaces/common";
import AbstractPopup, {AbstractPopupProps} from "../common/AbstractPopup";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import {MediaInfoForm} from "./MediaInfoForm";
import Api from "../../api/api";
import MediaImageEditor from "./MediaImageEditor";
import MediaInfo from "./MediaInfo";
import {ChevronBack, ChevronForward} from "react-ionicons";
import MediaImage from "./MediaImage";


interface MediaPopupViewerProps extends AbstractPopupProps {
    media: IMedia,
    onNext: () => void
    onPrev: () => void
    onClose: () => void
}

const MediaViewerPopup: FC<MediaPopupViewerProps> = (props) => {
    useWhyDidYouUpdate("MediaViewerPopup", props);
    const {media, onClose, onNext, onPrev} = props;


    const {isAuthorized} = useSelector((store: ApplicationState) => store.app);
    const [isEditMedia, setIsEditMedia] = useState(false);
    const [isEditInfo, setIsEditInfo] = useState(false);

    const [loading, setLoading] = useState(false);

    const [fullMedia, setFullMedia] = useState(media);

    useEffect(() => {
        setLoading(true);
        Api.getMedia(media.id)
            .then(data => {
                setLoading(false);
                const __fullMedia = (data.data);
                if (media.isUpdated) {
                    __fullMedia.url = __fullMedia.url + `?${Date.now()}`;
                    __fullMedia.thumbnailUrl = __fullMedia.thumbnailUrl + `?${Date.now()}`;
                }

                setFullMedia(__fullMedia);
            })

        window.location.hash = `media=${media.id}`
        document.title = `${media.title} | ${process.env.REACT_APP_TITLE}`;
        return () => {
            window.history.replaceState(null, "", ' ');
        }
    }, [media])

    const __onEdit = () => {
        setIsEditMedia(false);
    }

    return (
        <AbstractPopup onClose={onClose}>
            <div className={`media-viewer`}>
                {loading && <div className={"loading"} />}
                <div>
                    <div className="media-container">
                        {isEditMedia ?
                            <MediaImageEditor media={media} onEdit={__onEdit}/>
                            :
                            <MediaImage media={fullMedia} onChange={() => setIsEditMedia(prev => !prev)}/>
                        }
                    </div>
                </div>

                <div className="info">
                    {(isAuthorized && !isEditInfo) &&
                        <div className="popup-actions">
                            <button
                                className="btn btn-mini btn-edit"
                                onClick={() => setIsEditInfo(prev => !prev)}>Modifica info
                            </button>
                        </div>
                    }

                    {isEditInfo ?
                        <MediaInfoForm onClose={onClose}
                                       media={fullMedia}
                                       onCancel={() => setIsEditInfo(false)}
                                       onChange={(media) => {
                                           setFullMedia(media);
                                       }}/>
                        :
                        <MediaInfo media={fullMedia}/>
                    }

                    {(isAuthorized && fullMedia.duplicates && fullMedia.duplicates.length > 0) && <div className="media-duplicates">
                        <h3>Possibili duplicati</h3>
                        <div className="list">
                        {
                            fullMedia.duplicates.map(__media => {
                                return <a href={`#media=${__media.id}`} target="_blank" key={`duplicate-${__media.id}`}><img alt="" src={__media.thumbnailUrl} style={{width: "50px"}} /></a>
                            })
                        }
                        </div>
                    </div>}
                </div>

                <button className="media-viewer-nav back" onClick={onPrev}>
                    <ChevronBack color={"#fff"} height={"100"} width={"100"}/></button>
                <button className="media-viewer-nav forward" onClick={onNext}>
                    <ChevronForward color={"#fff"} height={"100"} width={"100"}/></button>
            </div>
        </AbstractPopup>
    );
};


export default MediaViewerPopup;
