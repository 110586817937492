import React, {FC} from 'react';
import useWhyDidYouUpdate from "../../hooks/useWhyDidYouUpdate";
import {IMedia} from "../../interfaces/common";
// @ts-ignore
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import {Trash, Warning} from 'react-ionicons'

interface MediaProps {
    media: IMedia
    onClick: (media: IMedia, event: React.MouseEvent) => void
    onDelete: (media: IMedia) => void
}

const Media: FC<MediaProps> = (props) => {
    useWhyDidYouUpdate("Media", props);
    const {media, onClick, onDelete} = props;
    const {isAuthorized, selectedMedia} = useSelector((store: ApplicationState) => store.app);

    const __onClick = (e: React.MouseEvent) => {
        onClick(media, e)
    }

    const __handleDragStart = (ev: React.DragEvent<HTMLDivElement>) => {
        if (!isAuthorized) return;
        const target = ev.target as HTMLDivElement;
        target.classList.add("dragged")
        ev.dataTransfer.setData("type", "media");
        ev.dataTransfer.setData("media", JSON.stringify(media));
    }

    const __handleDragEnd = (ev: React.DragEvent<HTMLDivElement>) => {
        if (!isAuthorized) return;
        const target = ev.target as HTMLDivElement;
        target.classList.remove("dragged")
    }

    const __onDelete = (e: React.MouseEvent) => {
        e.stopPropagation();
        onDelete(media);
    }

    const isSelected = selectedMedia.find(_ => _.id === media.id);

    return (
        <div className={`media-container ${isSelected ? "selected" : ""}`} onClick={__onClick}>
            <div className="media"
                 draggable
                 onDragStart={__handleDragStart}
                 onDragEnd={__handleDragEnd}
            >
                <div className="actions">
                    {isAuthorized &&
                        <button className="delete btn-danger" onClick={__onDelete}><Trash color={"#fff"}/></button>}
                    {(isAuthorized && media.duplicates && media.duplicates?.length > 0) &&
                        <div className="alert">
                            <Warning color={"#ffa100"} height={"30px"} width={"30px"}/>
                        </div>}
                </div>
                <LazyLoadImage src={media.thumbnailUrl} alt={media.title}/>
            </div>
            <span className="media-title">{media.title}</span>
        </div>
    );
};

export default Media;
