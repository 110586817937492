export function routeHash(hash: string) {
    const hashTemplate = /([a-zA-Z0-9]+)=([0-9]+)/

    const data = hashTemplate.exec(hash);

    if (data) {
        const result: { [key: string]: any } = {};
        result[data[1]] = data[2];

        return result;
    }
    return {}

}

export function routeUrl(path: string) {
    const urlTemplate = /\/([a-zA-Z0-9]+)\/([0-9]+)/

    const data = urlTemplate.exec(path);

    if (data) {
        const result: { [key: string]: any } = {};
        result[data[1]] = data[2];

        return result;
    }
    return {}
}

